import { Trans } from "@lingui/macro";

interface ILabelProps {
  labelName: string;
  extraClasses?: string;
}

type TLabelsProps = {
  name: string;
  backgroundColor: string;
  text: JSX.Element;
};

const labels: TLabelsProps[] = [
  {
    name: "hot",
    backgroundColor: "bg-alert",
    text: <Trans>Hot</Trans>,
  },
  {
    name: "updated",
    backgroundColor: "bg-[#FF6565]",
    text: <Trans>Updated</Trans>,
  },
  {
    name: "exclusive",
    backgroundColor: "bg-primary",
    text: <Trans>Exclusive</Trans>,
  },
  {
    name: "new",
    backgroundColor: "bg-green",
    text: <Trans>New</Trans>,
  },
];

const Label: React.FunctionComponent<ILabelProps> = ({
  labelName,
  extraClasses,
}) => {
  const selectedLabel = labels.find((label) => label.name == labelName);

  return (
    <div
      className={`${extraClasses} w-fit rounded ${selectedLabel.backgroundColor} px-2 py-1`}
    >
      <p className="text-center font-sen text-xs font-bold text-white">
        {selectedLabel.text}
      </p>
    </div>
  );
};

export default Label;
