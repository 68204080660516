import Link from "next/link";
import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import {
  LeftArrow,
  RightArrow,
} from "@components/core/horizontal-scroll-arrows";
import "react-toastify/dist/ReactToastify.css";
import {
  AllNewsStackQuery,
  useAllNewsStackQuery,
} from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";
import { errorToastPopup } from "@lib/errorToastPopup";
import Label from "@components/shared/label";

interface INewsStacksCarouselItemProps {
  newsStack: AllNewsStackQuery["allCategories"][0];
  itemId: any;
}

const NewsStacksCarousel: React.FunctionComponent = () => {
  const { locale } = useRouter();

  const { data, loading, refetch, error } = useAllNewsStackQuery({
    variables: { languageCode: locale },
  });

  useEffect(() => {
    if (!loading) {
      refetch({ languageCode: locale });
    }
  }, [locale, refetch, loading]);

  useEffect(() => {
    if (error) {
      errorToastPopup(error);
    }
  }, [error]);

  return (
    <div className="-mx-4 h-[58px] border-y">
      <div className="news-stacks-carousel mx-auto max-w-7xl">
        {loading && (
          <Trans>
            <p className="py-4 text-center font-sen text-text-lightest">
              Loading...
            </p>
          </Trans>
        )}
        {!error && (
          <ScrollMenu
            LeftArrow={LeftArrow}
            RightArrow={RightArrow}
            transitionDuration={500}
            transitionBehavior={"smooth"}
            scrollContainerClassName="my-4 -ml-4 pr-2"
          >
            {data?.allCategories.map((newsStack) => (
              <NewsStacksCarouselItem
                itemId={newsStack.id}
                key={newsStack.id}
                newsStack={newsStack}
              />
            ))}
          </ScrollMenu>
        )}
      </div>
    </div>
  );
};
const NewsStacksCarouselItem: React.FunctionComponent<
  INewsStacksCarouselItemProps
> = ({ newsStack: { hot, slug, exploreName, updated, exclusive } }, itemId) => {
  const router = useRouter();
  const { query } = router;

  return (
    <Link href={`/news-stack/${slug}`}>
      <a>
        <div className="flex whitespace-nowrap" id={itemId}>
          <div
            className={`font-sen hover:text-text-dark ${
              query["name"] === slug ? "text-primary2" : "text-text-medium"
            }`}
          >
            {exploreName}
          </div>
          <div className="ml-2">
            {exclusive ? (
              <Label labelName="exclusive" />
            ) : updated ? (
              <Label labelName="updated" />
            ) : (
              hot && <Label labelName="hot" />
            )}
          </div>
        </div>
      </a>
    </Link>
  );
};

export default NewsStacksCarousel;
