import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import { VisibilityContext } from "react-horizontal-scrolling-menu";

export const LeftArrow: React.FunctionComponent = () => {
  const { isFirstItemVisible, scrollPrev, initComplete } =
    useContext(VisibilityContext);

  return (
    <div
      className={`relative ml-6 items-center transition-[padding] ease-linear
       ${isFirstItemVisible ? "px-0" : "pl-1 lg:pl-2"}
       ${initComplete ? "flex" : "invisible"}
      `}
    >
      <Arrow
        className={
          "absolute -left-4 z-10 flex h-full w-[2em] items-center justify-center bg-gradient-to-r from-white to-transparent lg:w-[2em]"
        }
        style={{
          background:
            "linear-gradient(90deg, #FFFFFF 57.5%, rgba(255, 255, 255, 0) 100%)",
        }}
        disabled={initComplete ? isFirstItemVisible : true}
        onClick={() => scrollPrev()}
      >
        <ChevronLeftIcon
          className="h-10 w-6 text-primary2"
          aria-hidden="true"
        />
      </Arrow>
    </div>
  );
};

export const RightArrow: React.FunctionComponent = () => {
  const { isLastItemVisible, scrollNext, initComplete } =
    useContext(VisibilityContext);

  return (
    <div
      className={`relative items-center transition-[padding] ${
        isLastItemVisible ? "px-0" : "pr-2"
      } ${initComplete ? "flex" : "invisible"}`}
    >
      <Arrow
        className={
          "absolute z-10 flex h-full w-[2em] items-center justify-center bg-gradient-to-r from-white to-transparent lg:w-[4em]"
        }
        style={{
          background:
            "linear-gradient(270deg, #FFFFFF 57.5%, rgba(255, 255, 255, 0) 100%)",
        }}
        disabled={initComplete ? isLastItemVisible : true}
        onClick={() => scrollNext()}
      >
        <ChevronRightIcon
          className="h-10 w-6 text-primary2"
          aria-hidden="true"
        />
      </Arrow>
    </div>
  );
};

function Arrow({
  children,
  disabled,
  onClick,
  className = "",
  style = {},
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
  className?: string;
  style?: object;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${className} right-1 flex cursor-pointer transition-[opacity] ${
        disabled ? "opacity-0" : "opacity-100"
      }`}
      style={style}
    >
      {children}
    </button>
  );
}
